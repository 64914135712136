.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 100%;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-position: bottom right;
  padding: 0 3px 3px 0;
}

.react-resizable-handle-w {
  top: 0;
  left: 0 !important;
  bottom: 0;
  cursor: ew-resize;
}

.react-resizable-handle-w {
  left: 0;
}
.hover-handles .react-resizable-handle {
  display: none;
}
.hover-handles:hover .react-resizable-handle {
  display: block;
}

.multi-select input {
  --tw-ring-color: none !important
;
}

/*TODO: Find out why these default tailwind classes are not included*/
/*Tailwind fixes*/

.translate-x-full {
  transform: translateX(100%);
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

[type='checkbox']:checked,
[type='radio']:checked,
[type='checkbox']:indeterminate {
  background-color: #333 !important;
}
[type='checkbox']:disabled,
[type='radio']:disabled {
  background-color: #e2e8f0 !important;
}

.height-screen-minus-navbar {
  height: calc(100vh - 138px);
  overflow-y: auto;
  padding-bottom: 68px;
}

[data-testid='flowbite-accordion'] > button.text-gray-900 {
  background: #f3f4f6;
}

[data-testid='flowbite-accordion-arrow'] {
  fill: black !important;
}

.custom-modal {
  & > div {
    & > div {
      max-width: 380px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

[data-testid='modal-overlay'] {
  background-color: rgba(75, 85, 99, 0.5) !important;
  & > div {
    & > div {
      max-width: 450px;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

/**
  * Custom styles for the react-datepicker library
  */
.react-datepicker-wrapper {
  @apply text-sm text-gray-500;
}

.react-datepicker__view-calendar-icon input {
  @apply rounded-lg border border-gray-300 bg-gray-50 !px-3 !py-2 !pl-6 text-sm;
}

.react-datepicker__view-calendar-icon input:disabled {
  @apply cursor-not-allowed opacity-50;
}

.react-datepicker__calendar-icon {
  @apply absolute left-3.5 top-1/2 h-3.5 w-3.5 -translate-x-1/2 -translate-y-1/2;
}

.react-datepicker__close-icon::after {
  @apply !bg-gray-500;
}

.react-datepicker__day--selected {
  @apply !bg-zinc-950 !text-white hover:bg-zinc-950/50;
}

.custom-combobox-button[aria-expanded='false'] svg {
  @apply rotate-0;
}

.custom-combobox-button[aria-expanded='true'] svg {
  @apply rotate-180;
}

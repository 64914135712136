@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family:
    Inter,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --accent: 0 0% 96.1%;
  --accent-foreground: 0 0% 9%;
}

body {
  color: rgb(var(--foreground-rgb));
}

.resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 10px;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

th {
  border-right: 1px solid #e5e7eb;
  &:last-of-type {
    border-right: 0;
  }
}

.-z-10 {
  z-index: -10;
}

.pagination {
  & ul {
    & li:first-of-type {
      & button {
        border-radius: 4px 0 0 4px;
      }
    }
    & li:last-of-type {
      & button {
        border-radius: 0 4px 4px 0;
      }
    }
    & [aria-current='page'] {
      background: #1f2a37;
      color: #d1d5db;
    }
  }
}
